.container {
    margin-top: 50px;
    text-align: center;
}

.title {
    font-size: 33px;
    font-weight: 600;
}
.content {
    margin-top: 20px;

}


