.container {
    margin-top: 15px;
    text-align: center;
}

.title {
    font-size: 33px;
    font-weight: 600;
}
.content {
    text-align: center;
    width: 70%;
    display: block;
    margin: 20px auto;
}
.notes {
    text-align: left;
    margin-left: 20%;

}


