.container {
    padding: 20px 20px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.box {
    margin-top: 10px;
    /* height: 200px; */
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 15px;
}

.box_title {
    font-size: 15px;
    font-weight: 400;
}
.copy {
    width: 5px;
    height: 5px;
}